<script setup>
import { computed } from 'vue';
import { generate } from 'vendor/text-to-image';
import { computedAsync } from '@vueuse/core';

const props = defineProps({
  text: { type: String, default: null },
  name: { type: String, default: null },
  width: { type: Number, required: true },
  height: { type: Number, required: true },
});

const colorHash = (inputString) => {
  let sum = 0;

  for (const i in inputString) {
    sum += inputString.charCodeAt(i);
  }

  const r = ~~(('0.' + Math.sin(sum + 1).toString().substring(6)) * 256);
  const g = ~~(('0.' + Math.sin(sum + 2).toString().substring(6)) * 256);
  const b = ~~(('0.' + Math.sin(sum + 3).toString().substring(6)) * 256);

  const rgb = 'rgb(' + r + ', ' + g + ', ' + b + ')';

  const color = Math.round(((r * 299) + (g * 587) + (b * 114)) / 1000);
  const light = (color > 125);

  const asHex = (value) => {
    const h = `00${value.toString(16)}`;
    return h.substring(h.length - 2, h.length).toUpperCase();
  };

  const hex = `#${asHex(r)}${asHex(g)}${asHex(b)}`;

  return { r, g, b, rgb, hex, light };
};

const shortName = (text) => {
  const parts = text.toLocaleUpperCase().split(/\s+/);
  if (parts.length > 1) {
    return parts[0][0] + parts[1][0];
  } else {
    return parts[0].substring(0, 2);
  }
};


const theText = computed(() => {
  return props.text || shortName(props.name || 'Unknown');
});

const imageSrc = computedAsync(
  async () => {
    // access the computed property before passing to async
    const text = theText.value;
    const color = colorHash(props.name || text);

    return await generate(text,
      {
        maxWidth: props.width,
        customHeight: props.height,
        textAlign: 'center',
        verticalAlign: 'center',
        bgColor: color.hex,
        textColor: color.light ? 'black' : 'white',
      });
  },
  null,
);
</script>

<template lang="pug">
img(v-if="imageSrc" :src="imageSrc")
OSkeleton(v-else :width="width + 'px'" :height="height + 'px'")
</template>

<style scoped>

</style>
