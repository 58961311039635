<script setup>
import { computed, getCurrentInstance, ref } from 'vue';
import _sortBy from 'lodash/sortBy.js';

const props = defineProps({
  /**
   *  @type {object[]}
   */
  data: {
    type: Array,
    required: true,
  },
  cellWrapperClass: {
    type: [String, Array],
    default: 'box',
  },
  cellSizingClass: {
    type: [String, Array],
    default: undefined,
  },
  // When false turn off growing of tiles
  cellGrow: {
    type: Boolean,
    default: true,
  },
  /**
   * How many columns to make the cell width.
   * There are a total of 12 columns.
   */
  cellColumns: {
    type: Number,
    default: null,
  },
  firstCell: {
    type: Boolean,
    default: false,
  },
  lastCell: {
    type: Boolean,
    default: false,
  },
  /**
   * How many columns to make the last cell
   */
  lastCellColumns: {
    type: Number,
    default: null,
  },
  loading: {
    type: Boolean,
    default: false,
  },
  /** If true then do not set is-ancestor on topmost tile */
  nested: Boolean,
  /**
   * The custom property of the object to use as the ID (generally id)
   */
  customRowKey: {
    type: String,
    default: null,
  },
  selected: {
    type: undefined,
    default: undefined,
  },
  /** If true then clicking anywhere on a cell will trigger a select event */
  selectable: Boolean,
  /** If true then allow dragging & dropping of cells */
  draggable: Boolean,
  /**
   * Either a property path string, or an array of property paths,
   * or a function that will return a single value or array of values
   * to sort by.
   *
   *  @type {string|string[]|function(object): string|string[]}
   */
  sortBy: {
    type: [String, Array, Function],
    default: null,
  },
  /**
   * Either asc, or desc
   */
  sortOrder: {
    type: String,
    default: 'asc',
  },
});

const emit = defineEmits(['select', 'update:selected', 'dropCell']);

const DEFAULT_DRAG_DATA = {
  /** @type {number|null} */
  tableUID: null,
  /** @type {number|null} */
  sourceCellIdx: null,
  /** @type {number|null} */
  targetCellIdx: null,
  /** @type {string|null} */
  targetSide: null,
  /** @type {Element|null} */
  targetElement: null,
};

const dragData = ref({ ...DEFAULT_DRAG_DATA });

const cellParentClasses = computed(() => {
  const classes = [];
  if (props.cellColumns) {
    classes.push(`is-${props.cellColumns}`);
  }
  return classes;
});

const cellWrapperClasses = computed(() => {
  const classes = [];
  if (Array.isArray(props.cellWrapperClass)) {
    classes.push(...props.cellWrapperClass);
  } else {
    classes.push(props.cellWrapperClass);
  }
  if (props.cellSizingClass) {
    if (Array.isArray(props.cellSizingClass)) {
      classes.push(...props.cellSizingClass);
    } else {
      classes.push(props.cellSizingClass);
    }
  }
  return classes;
});

const lastCellParentClasses = computed(() => {
  const classes = [];
  if (props.lastCellColumns) {
    classes.push(`is-${props.lastCellColumns}`);
  } else {
    classes.push(...cellParentClasses.value);
  }
  return classes;
});

const sortedData = computed(() => {
  if (props.sortBy) {
    const sorted = _sortBy(props.data, props.sortBy);
    if (props.sortOrder === 'desc') {
      sorted.reverse();
    }
    return sorted;
  } else {
    return props.data;
  }
});

const selectedIdx = computed(() => {
  const idx = sortedData.value.findIndex(isSelected);
  return idx < 0 ? null : idx;
});

function selectCell(cell, ui = false) {
  if (props.selectable) {
    emit('update:selected', cell);
    emit('select', cell, { ui });
  }
}

const hasNext = computed(() => {
  if (selectedIdx.value === null) {
    return sortedData.value.length > 0;
  } else {
    return selectedIdx.value < sortedData.value.length - 1;
  }
});

const hasPrev = computed(() => {
  if (selectedIdx.value === null) {
    return sortedData.value.length > 0;
  } else {
    return selectedIdx.value > 0;
  }
});

const vm = getCurrentInstance();

function nextCell() {
  let idx = 0;
  if (selectedIdx.value !== null) {
    idx = selectedIdx.value + 1;
  }
  if (idx >= sortedData.value.length) {
    idx = sortedData.value.length - 1;
  }
  let cell = null;
  if (idx >= 0) {
    cell = sortedData.value[idx];
  }
  selectCell(cell);
}

function prevCell() {
  let idx = sortedData.value.length - 1;
  if (selectedIdx.value !== null) {
    idx = selectedIdx.value - 1;
  }
  if (idx < 0) {
    idx = 0;
  }
  let cell = null;
  if (idx >= 0) {
    cell = sortedData.value[idx];
  }
  selectCell(cell);
}

const top = ref(null);

function elementForCell(cell) {
  if (props.customRowKey) {
    return top.value.querySelector(`.tile[data-id="${cell[props.customRowKey]}"]`);
  } else {
    console.warn('customRowKey is required');
  }
}

function isSelected(cell) {
  if (!props.selected) return false;

  if (props.customRowKey) {
    return cell[props.customRowKey] === props.selected[props.customRowKey];
  }
  return cell === props.selected;
}

function dataCellClasses(cell, index) {
  return [{
    selectable: props.selectable,
    selected: isSelected(cell),
    dragging: index === dragData.value.sourceCellIdx,
    dropping: index === dragData.value.targetCellIdx,
    'dropping-left': index === dragData.value.targetCellIdx && dragData.value.targetSide === 'left',
    'dropping-right': index === dragData.value.targetCellIdx && dragData.value.targetSide === 'right',
  }, ...cellParentClasses.value];
}

function findParentCell(node) {
  return node.closest('.tile-cell > .is-child[draggable="true"]');
}

function handleDragStart(event, cellIdx) {
  if (!props.draggable)
    return;

  event.dataTransfer.clearData();
  event.dataTransfer.setData('application/x-tile-drag', vm.uid);
  event.dataTransfer.effectAllowed = 'move';
  dragData.value.sourceCellIdx = cellIdx;
  dragData.value.tableUID = vm.uid;
  const cellNode = findParentCell(event.target);
  const cellPos = cellNode.getBoundingClientRect();
  event.dataTransfer.setDragImage(cellNode, event.clientX - cellPos.left, event.clientY - cellPos.top);
  // console.log('dragStart', cellIdx, vm.uid, event, cellNode);
}

function determineSide(event, defaultSide = null) {
  const cellNode = findParentCell(event.target);
  const cellPos = cellNode.getBoundingClientRect();
  const x = event.clientX - cellPos.left;
  const allowedWidth = cellPos.width * 0.4;

  if (x < allowedWidth) return 'left';
  if (x > (cellPos.width - allowedWidth)) return 'right';
  return defaultSide;
}

function handleDragEnter(event, cellIdx) {
  if ([...event.dataTransfer.types].includes('application/x-tile-drag')) {
    if (dragData.value.tableUID === null || dragData.value.tableUID !== vm.uid) {
      return;
    }
    if (dragData.value.sourceCellIdx === cellIdx) {
      /// Cannot drop on source
      return;
    }
    if (dragData.value.targetCellIdx !== cellIdx) {
      dragData.value.targetCellIdx = cellIdx;
      dragData.value.targetSide = determineSide(event, 'left');
    }
    dragData.value.targetElement = event.target;
    // console.log('dragEnter src:', dragData.value.sourceCellIdx, 'dest:', cellIdx, 'side:', side, event.target);
    event.preventDefault();
  }
}

function handleDragLeave(event, _cellIdx) {
  if ([...event.dataTransfer.types].includes('application/x-tile-drag')) {
    if (dragData.value.tableUID === null || dragData.value.tableUID !== vm.uid) {
      return;
    }
    if (dragData.value.targetElement === event.target) {
      // const idx = dragData.value.sourceCellIdx;
      // console.log('dragLeave src:', idx, 'dest:', cellIdx, event.target);
      dragData.value.targetCellIdx = null;
      dragData.value.targetSide = null;
      dragData.value.targetElement = null;
      event.preventDefault();
    }
  }
}

function handleDragOver(event, cellIdx) {
  if ([...event.dataTransfer.types].includes('application/x-tile-drag')) {
    if (dragData.value.tableUID === null || dragData.value.tableUID !== vm.uid) {
      return;
    }
    if (dragData.value.sourceCellIdx === cellIdx) {
      /// Cannot drop on source
      return;
    }
    if (dragData.value.targetCellIdx !== cellIdx) {
      console.error('Enter event missed!');
    }
    const side = determineSide(event);
    if (side && dragData.value.targetSide !== side) {
      dragData.value.targetSide = side;
      // console.log('dragOver src:', dragData.value.sourceCellIdx, 'dest:', cellIdx, 'side:', side, event);
    }

    event.preventDefault();
  }
}

function handleDragDrop(event, _cellIdx) {
  const uid = event.dataTransfer.getData('application/x-tile-drag');
  event.preventDefault();
  if (uid !== vm.uid.toString()) return;

  const sourceCell = sortedData.value[dragData.value.sourceCellIdx];
  const targetCell = sortedData.value[dragData.value.targetCellIdx];

  emit('dropCell', {
    sourceCell, targetCell,
    sourceCellIndex: dragData.value.sourceCellIdx,
    targetCellIndex: dragData.value.targetCellIdx,
    targetSide: dragData.value.targetSide,
    dropEffect: event.dataTransfer.dropEffect,
  });
}

function handleDragEnd(_event, _cellIdx) {
  dragData.value = { ...DEFAULT_DRAG_DATA };
}

defineExpose({ nextCell, prevCell, hasNext, hasPrev, elementForCell });
</script>

<template lang="pug">
.tile-table.tile.top(ref="top" :class="{'is-ancestor': !nested, 'no-grow': !cellGrow}")
  .tile.is-parent(v-if="firstCell" :class="cellParentClasses")
    .tile.is-child(:class="cellWrapperClasses")
      slot(name="first")
  .tile-cell.tile.is-parent(
    v-for="(cell, index) in sortedData"
    :key="customRowKey ? cell[customRowKey] : index"
    :data-id="customRowKey ? cell[customRowKey] : index"
    :class="dataCellClasses(cell, index)"
  )
    .tile.is-child(
      :class="cellWrapperClasses"
      :draggable="draggable ? 'true' : ''"
      @click="selectCell(cell, true)"
      @dragstart="handleDragStart($event, index)"
      @dragend="handleDragEnd($event, index)"
      @dragover="handleDragOver($event, index)"
      @dragenter="handleDragEnter($event, index)"
      @dragleave="handleDragLeave($event, index)"
      @drop="handleDragDrop($event, index)"
    )
      slot(name="cell" :cell="cell" :index="index")
      .overlay
  .tile.is-parent(v-if="lastCell" :class="lastCellParentClasses")
    .tile.is-child(:class="cellWrapperClasses")
      slot(name="last")

  slot(name="loading")
    OLoading(:full-page="false" :active="loading")
</template>

<style scoped lang="scss">
.top {
  flex-wrap: wrap;
}

.tile-table.no-grow .tile {
  flex-grow: 0;
}

.tile-cell {
  &.selectable > .tile {
    cursor: pointer;
  }

  &.dragging > .tile {
    opacity: 0.5;
  }

  & > .tile > .overlay {
    display: none;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    position: absolute;
  }

  &.dropping > .tile {
    > .overlay {
      display: block;
      background-color: rgba(108, 190, 100, 0.5);
    }
  }

  &.dropping-left > .tile {
    > .overlay {
      right: 60%;
    }
  }

  &.dropping-right > .tile {
    > .overlay {
      left: 60%;
    }
  }
}

</style>
