export const IMAGE_FORMATS = ['image/*'];

export const HEADSHOT_FORMATS = [
  ...IMAGE_FORMATS,
  'application/pdf',
];

export const RESUME_FORMATS = [
  'application/pdf',
  ...IMAGE_FORMATS,
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.oasis.opendocument.text',
];

export const RESUME_MAX_SIZE = 8 * 1024 * 1024;
